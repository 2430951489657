import { ACCOUNT_PARAM } from "../../accounts/configs/AccountsApiRoutes";
import { LOCATION_PARAM, LOCATION_ROUTE, LocationRequestParams } from "../../locations/configs/LocationsApiRoutes";

/**
 * Key used in route or query params
 */
export const TABLE_KEY = 'tableId';

export const TABLE_ID_PARAM_KEY = "tableId";
export const TABLE_ID_PARAM = `:${TABLE_ID_PARAM_KEY}`;
export const TABLE_REF_PARAM_KEY = "tableRef";
export const TABLE_REF_PARAM = `:${TABLE_REF_PARAM_KEY}`;

export const LOCATION_TABLES_ROOT = `${LOCATION_ROUTE}/tables`;
export const TABLE_BY_ID = `${LOCATION_TABLES_ROOT}/${TABLE_ID_PARAM}`;
export const TABLE_CLOSE = `${TABLE_BY_ID}/close`;

export type TableRequestParams = LocationRequestParams & { [TABLE_KEY]: string }

export const getLocationTablesRoute = (accountId: string, locationId: string) => {
    return LOCATION_TABLES_ROOT
        .replace(ACCOUNT_PARAM, accountId)
        .replace(LOCATION_PARAM, locationId)
}

export const getLocationTableRoute = (accountId: string, locationId: string, tableId: string) => {
    return TABLE_BY_ID
        .replace(ACCOUNT_PARAM, accountId)
        .replace(LOCATION_PARAM, locationId)
        .replace(TABLE_ID_PARAM, tableId)
}

export const getTableCloseRoute = (accountId: string, locationId: string, tableId: string) => {
    return TABLE_CLOSE
        .replace(ACCOUNT_PARAM, accountId)
        .replace(LOCATION_PARAM, locationId)
        .replace(TABLE_ID_PARAM, tableId);
}

const tablesApiRoutes = {
    LOCATION_TABLES_ROOT,
    TABLE_BY_ID,
    TABLE_CLOSE,
};
export default tablesApiRoutes;